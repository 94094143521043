import TableCellBtcFormat from '@/components/table/renderer/TableCellBtcFormat.vue';
import TableCellCoin from '@/components/table/renderer/TableCellCoin.vue';
import TableCellCurrency from '@/components/table/renderer/TableCellCurrency.vue';
import TableCellDollar from '@/components/table/renderer/TableCellDollar.vue';
import TableCellDollarFormat from '@/components/table/renderer/TableCellDollarFormat.vue';
import TableCellMillionFormat from '@/components/table/renderer/TableCellMillionFormat.vue';
import TableCellPercentDifference from '@/components/table/renderer/TableCellPercentDifference.vue';
import TableCellRawDelta from '@/components/table/renderer/TableCellRawDelta.vue';
import TableCellPercent from '@/components/table/renderer/TableCellPercent.vue';
import TableCellNumber from '@/components/table/renderer/TableCellNumber.vue';

export function getCellRenderer(colType) {
  const typeMappings = {
    dollar: TableCellDollar,
    price: TableCellDollar,
    dollar_format: TableCellDollarFormat,
    live_price: TableCellDollar,
    number: TableCellNumber,
    percent: TableCellPercentDifference,
    btc_format: TableCellBtcFormat,
    coin: TableCellCoin,
    timestamp: 'CellDateTime',
    simple_percent: TableCellPercent,
    small_percent: TableCellPercent,
    currency: TableCellCurrency,
    million_format: TableCellMillionFormat,
    delta: TableCellRawDelta
  };
  return typeMappings[colType] || 'BaseCellNumber';
}
