<template>
  <div class="h-full w-full" v-if="Object.values(isDataReady).every(v => v === true)">
    <BaseTable
      class="h-full w-full"
      :column-defs="columnDefs"
      :row-data="rowData"
      :default-col-def="defaultColDef"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
    />
  </div>
  <div v-else class="flex flex-col pr-8">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
</template>

<script setup>
import { ref, watch, onMounted, inject, computed, onBeforeMount } from 'vue';
import { getCellRenderer } from '@/components/table/renderer/gridUtils.js';
import { useStore } from 'vuex';

const $http = inject('http');
const emit = defineEmits(['select']);

const isDataReady = ref({ table: false });

const props = defineProps({
  alertData: {
    type: Object,
    default: null
  },
  alertCoins: {
    type: Array,
    default: null
  }
});

const store = useStore();
const gridApi = ref(null);
const selectedRow = ref(null);

const coins = computed(() => store.getters.coins);
const coinDatapoints = computed(() => store.getters.coinDatapoints);

onBeforeMount(() => {
  getTableData();
});

onMounted(() => {
  getTableData();
});

watch(
  () => props.alertCoins,
  async (newVal, oldVal) => {
    getTableData();
  }
);

// AG Grid Event Functions

const onGridReady = params => {
  gridApi.value = params.api;
};

const firstDataRendered = () => {
  // gridApi.value.sizeColumnsToFit();
};

// Row Logic

const tableCoinsData = ref([]);

function getTableData() {
  isDataReady.value.table = false;
  const tableCoins = props.alertCoins;
  tableCoinsData.value = mapCoinsForTable(tableCoins);
  isDataReady.value.table = true;
}

function mapCoinsForTable(coins) {
  let ret = coins.map(coin => {
    return {
      uid: coin
    };
  });
  return ret;
}

const percDifference = (a, b) => {
  if (!a || !b) {
    return null;
  }
  a = Number(a);
  b = Number(b);
  return (b - a) / a;
};

const rowData = computed(() => {
  let uniqueAddMetrics = additionalMetricCols.value;
  let tableData = tableCoinsData.value.map(row => {
    const curr_coin = coins.value.filter(coin => coin.coin_uid == row.uid);
    let cols = {
      ...row,
      uid: row.uid,
      coin: {
        name: curr_coin.length ? curr_coin[0].name : '',
        ticker: curr_coin.length ? curr_coin[0].ticker : '',
        uid: row.uid
      },
      live_price: curr_coin.length ? curr_coin[0].price : '',
      priceValue: {
        name: curr_coin.length ? curr_coin[0].name : '',
        ticker: curr_coin.length ? curr_coin[0].ticker : '',
        dollars: curr_coin.length ? curr_coin[0].price : ''
      }
    };
    uniqueAddMetrics.forEach((metric, i) => {
      const metrics = props.alertData.metrics.filter(e => e.id == metric.field);
      cols[metric.field] = curr_coin.length ? curr_coin[0][metric.field] : '';
      metrics.forEach((e, i) => {
        cols[`${metric.field}-metric_${i}`] = e.value;
      });
    });
    return cols;
  });

  return tableData;
});

// Column Logic

const defaultColDef = {
  flex: 1,
  sortable: true,
  resizable: true,
  filter: false
};

const baseColumns = ref([
  {
    headerName: 'Coin',
    field: 'coin',
    cellRenderer: getCellRenderer('coin'),
    minWidth: 200,
    resizable: true,
    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
      if (valueA.uid == valueB.uid) return 0;
      return valueA.uid > valueB.uid ? 1 : -1;
    }
  }
]);

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()];
}

const uniqueMetricsSeries = computed(() => {
  return getUniqueListBy(
    props.alertData.metrics.filter(metric => metric.id != null),
    'id'
  );
});

const additionalMetricCols = computed(() => {
  let uniqueAddMetrics = uniqueMetricsSeries.value.map(metric => metric.id);
  return uniqueAddMetrics.map(id => {
    let metrics = props.alertData.metrics.filter(metric => metric.id == id);
    const compare_vals = metrics.map((e, i) => {
      const metric_field_id = `${e.id}-metric_${i}`;
      return {
        field: metric_field_id,
        headerName: 'Distance',
        type: 'rightAligned',
        width: 60,
        maxWidth: 80,
        format_type: e.type,
        valueGetter: params => parseFloat(params.data[metric_field_id]),
        cellRenderer: getCellRenderer('delta')
      };
    });

    return {
      headerName: metrics[0].name,
      type: 'rightAligned',
      field: metrics[0].id,
      children: [
        {
          field: metrics[0].id,
          headerName: 'Value',
          type: 'rightAligned',
          valueGetter: params => {
            if (params.data[metrics[0].id] == undefined || params.data[metrics[0].id] == null) return null;
            else {
              return metrics[0].id == 'daily_sentiment'
                ? parseFloat(params.data[metrics[0].id]).toFixed(0)
                : parseFloat(params.data[metrics[0].id]);
            }
          },
          cellRenderer: getCellRenderer(metrics[0].type)
        }
      ].concat(compare_vals)
    };
  });
});

const columnDefs = computed(() => {
  return baseColumns.value.concat(additionalMetricCols.value);
});
</script>
